import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "./Nav.module.css"

const Nav = () => (
  <div
    className={styles.container}
  >

      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `#ffffff`,
            textDecoration: `none`,
            background: `hsla(199, 89%, 91%,1)`,
            padding: `3px 7px`
          }}
        >
          dtc go
        </Link>
      </h1>
  </div>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav

import React from 'react'
import Nav from "../components/Nav"
import * as styles from "./index.module.css"

const EmailSignupConfirmation = () => {
    return (
            <div >
                <Nav/>
                <div className={styles.headerContainer}>
                    Thank you for subscribing. Please check your inbox for email confirmation link.
                    <br></br>
                   If you do not receive our email in your inbox. Be sure to check your promotions tab or spam folder.
                </div>
                
            </div>
  
     
    )
}

export default EmailSignupConfirmation
